import { FC } from "react";

import { Box, Collapse, Skeleton, Stack, useMediaQuery } from "@mui/material";
import { FlightCard } from "@/components/FlightCard/FlightCard";
import { DashboardHeader } from "@/components/PageIndex/Elements/DashboardHeader";
import Link from "next/link";

import { useAuthentication } from "@fllite/ui/hooks";
import { Text } from "@fllite/ui/components";
import { usePublishedFlights } from "@fllite/ui/hooks";
import { muiTheme } from "@fllite/ui/themes";

import {
  Container,
  FlightsContainer,
  MoreLinkContainer,
  MoreLinkIcon,
  Section,
} from "./JumpOnAFlight.styles";
import { JumpOnAFlightBanner } from "./JumpOnAFlightBanner";
import { JumpOnAFlightEmpty } from "./JumpOnAFlightEmpty";
import { JumpOnAFlightLoader } from "./JumpOnAFlightLoader";

const MAX_ITEMS = 3;

interface AllFlightsLinkProps {
  isLoading: boolean;
  flightsCount: number;
}
const AllFlightsLink: FC<AllFlightsLinkProps> = ({
  isLoading,
  flightsCount,
}) => (
  <MoreLinkContainer>
    {isLoading && <Skeleton variant="text" width="20%" />}
    {!isLoading && flightsCount > 0 && (
      <Link href="/jump-on-a-flight" passHref>
        <Text primary medium noWrap>
          View All Shared Trips
          <MoreLinkIcon type="arrow-right-blue" />
        </Text>
      </Link>
    )}
  </MoreLinkContainer>
);

export const JumpOnAFlight = ({ hideJumpOnAFlightSection = false }) => {
  const { user } = useAuthentication();

  const isDesktopMatch = useMediaQuery(muiTheme.breakpoints.up("md"));

  const { publishedFlights: flights, isLoading } = usePublishedFlights({
    itemsPerPage: MAX_ITEMS,
    inquiryFirstLegDepartureAirport:
      (user !== false && user?.homebaseAirport?.id) || 0,
  });

  return (
    <Section hideJumpOnAFlightSection={hideJumpOnAFlightSection}>
      <Collapse in={!hideJumpOnAFlightSection} timeout="auto">
        <Container>
          <Stack
            direction="row"
            alignItems="flex-end"
            spacing={1}
            marginBottom="40px"
          >
            <DashboardHeader
              labelId="Grab a seat!"
              sublabelId="Not feeling like booking the whole aircraft? Just jump on a shared trip and pay only for the price per seat."
            />
            {isDesktopMatch && (
              <AllFlightsLink
                isLoading={isLoading}
                flightsCount={flights.length}
              />
            )}
          </Stack>
        </Container>
      </Collapse>
      {hideJumpOnAFlightSection ? (
        <JumpOnAFlightBanner />
      ) : (
        <>
          {isLoading && (
            <FlightsContainer numberOfFlights={MAX_ITEMS}>
              <JumpOnAFlightLoader maxItems={MAX_ITEMS} />
            </FlightsContainer>
          )}
          {!isLoading && flights.length > 0 && (
            <FlightsContainer numberOfFlights={flights.length}>
              {flights
                .filter((flight) => flight.id)
                .slice(0, MAX_ITEMS)
                .map((flight) => (
                  <FlightCard key={flight.id} data={flight} />
                ))}
            </FlightsContainer>
          )}
          {!isDesktopMatch && (
            <Box mt={3}>
              <AllFlightsLink
                isLoading={isLoading}
                flightsCount={flights.length}
              />
            </Box>
          )}

          {!isLoading && flights.length === 0 && <JumpOnAFlightEmpty />}
        </>
      )}
    </Section>
  );
};
