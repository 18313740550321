/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Image from "next/image";
import Link from "next/link";

import { Button } from "@fllite/ui/components";

import {
  ContainerLikeNotLink,
  HalfCircle,
  SeparatorWrapper,
} from "../../../FlightCard/FlightCard.styles";

const Wrapper = styled(ContainerLikeNotLink)`
  justify-items: center;
  align-items: center;
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    max-width: 400px;
  }
`;

const FlyImage = styled(Image)``;

const Separator = styled(SeparatorWrapper)`
  position: absolute;
  margin-top: 40px;
`;

const Content = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  max-width: 200px;
  margin-bottom: 16px;
`;

const Description = styled(Typography)`
  margin: 0 30px 16px;
`;

const StyledButton = styled(Button)`
  min-height: 42px;
  width: 240px;
  padding: 5px 20px;
  margin-top: 20px;
  margin-bottom: 48px;

  ${({ theme }) => (theme as any).breakpoints.up("sm")} {
    margin-top: 0;
  }
`;

export const JumpOnAFlightBanner = () => {
  return (
    <Wrapper isInShade={false} withClickAbleContent>
      <FlyImage
        src="/assets/jump-on-a-flight/banner-chair.svg"
        width={324}
        height={140}
        alt="Chair"
      />
      <Separator>
        <HalfCircle isOnLeftSide customBackgroundColor="#e5e5e5" />
        <HalfCircle customBackgroundColor="#e5e5e5" />
      </Separator>
      <Content>
        <Title>Grab a seat!</Title>
        <Description>
          Not feeling like booking the whole aircraft? Just jump on a shared
          trip and pay only for the price per seat.
        </Description>
        <Link href="/jump-on-a-flight">
          <StyledButton data-testid="banner-raf" outlinePrimary>
            View All Shared Trips
          </StyledButton>
        </Link>
      </Content>
    </Wrapper>
  );
};
