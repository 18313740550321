import { Skeleton } from "@mui/material";

import {
  BoxesRow,
  ContainerLikeNotLink,
  HalfCircle,
  PaddingBox,
  RadiusPlaceholderBox,
  SeparatorWrapper,
  StyledSeparatorThicker,
} from "./FlightCard.styles";

export const FlightCardPlaceholder = () => (
  <ContainerLikeNotLink isInShade={false}>
    <div style={{ padding: "8px" }}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={178}
        sx={{ marginBottom: "10px" }}
      />
    </div>
    <SeparatorWrapper>
      <StyledSeparatorThicker dashed />
      <HalfCircle isOnLeftSide customBackgroundColor="#e5e5e5" />
      <HalfCircle customBackgroundColor="#e5e5e5" />
    </SeparatorWrapper>
    <div>
      <PaddingBox>
        <Skeleton variant="text" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" />
      </PaddingBox>
      <BoxesRow>
        <RadiusPlaceholderBox>
          <Skeleton
            variant="rectangular"
            height="58px"
            sx={{ borderRadius: 2 }}
          />
        </RadiusPlaceholderBox>
        <RadiusPlaceholderBox>
          <Skeleton
            variant="rectangular"
            height="58px"
            sx={{ borderRadius: 2 }}
          />
        </RadiusPlaceholderBox>
        <RadiusPlaceholderBox>
          <Skeleton
            variant="rectangular"
            height="58px"
            sx={{ borderRadius: 2 }}
          />
        </RadiusPlaceholderBox>
      </BoxesRow>
    </div>
  </ContainerLikeNotLink>
);
