import { FC, useMemo } from "react";

import { FlightCardPlaceholder } from "@/components/FlightCard/FlightCardPlaceholder";

interface JumpOnAFlightLoaderProps {
  maxItems: number;
}

export const JumpOnAFlightLoader: FC<JumpOnAFlightLoaderProps> = ({
  maxItems,
}) => {
  const items = useMemo(
    () => Array.from({ length: maxItems }, (_, i) => i),
    [maxItems],
  );

  return (
    <>
      {items.map((item) => (
        <FlightCardPlaceholder key={item} />
      ))}
    </>
  );
};
